body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*.App {
  height: 100%;
  width: 100%;
}*/

.menu {
  background: #1C2F27;
  color: white;
  font-size: 1.2em;
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
  top:0;
  position: -webkit-sticky;
  position: sticky;
  font-family: 'Staatliches', cursive;
  z-index: 10;
  padding: .5%;
}

.current-menu {
  border-style: solid;
  border-color: white;
  border-width: 0px 0px 0.5vh 0px;
}

#overview, #people, #results, #news, #contact {
  padding:.5%;
}

.homepage {
  margin-top: 0%;
}

.home-title {
  font-size: 3em;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Staatliches', cursive;
  padding: 2%;
  padding-top: 5%;
}

.home-divider {
  border: solid #1C2F27 0.5vh;
  width: 2vw;
  display: inline-block;
  background: #1C2F27;
}

.home-subtitle, .icon-title, .people-title-odd, .people-title-even {
  text-align: center;
  font-family: 'Staatliches', cursive;
  font-size: 1.5em;
  padding: 2em;
  padding-bottom: 1em;
}

.home-quote {
  font-size: 2em;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-bottom: 2em;
  padding-top: 2em;
  font-family: 'Nixie One', cursive;
  text-align: center;
}

.centering-guide{
  width: 100%;
  text-align: center;
}

.slider-circle, .current-slider {
  background: transparent;
  display: inline-block;
  width: 1vh;
  height: 1vh;
  border-radius: 50%;
  border: solid black 2px;
  margin: 1%;
  margin-bottom: 2%;
}

.current-slider {
  background: black;
}

.down-arrow, .back-arrow, .front-arrow {
  display: inline-block;
  box-sizing: border-box;
  height: 3vh;
  width: 3vh;
  border-style: solid;
  border-color: black;
  border-width: 0px 1px 1px 0px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
  margin: 2%;
}

.down-arrow:hover, .back-arrow:hover, .front-arrow:hover{
  border-bottom-width: 5px;
  border-right-width: 5px;
}

.overview-text {
  font-family: 'Nixie One', cursive;
  font-size: 1.2em;
  line-height: 1.5em;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0;
}

.overview-description {
  width: 50%;
  padding: 5%;
  background: #1C2F27;
  color:white;
}

.overview-icons {
  padding: 5%;
  padding-top: 0;
}

/*.icon-title {
  font-size: 1.5em;
  display: inline-block;
  padding-bottom: 5%;
  font-family: 'Staatliches', cursive;
}*/

.icon {
  width: 10vw;
  height: 10vw;
}

.icon-row {
  display: flex;
  flex-wrap: nowrap;
}

.icon-description {
  text-align: center;
  display: inline-block;
}

.diagram-slider-top{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  height: 90vh;
}

.back-arrow {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

.front-arrow {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.diagram-content{
  width: 50vw;
}

.people-title {
  font-size: 1.5em;
  text-align: center;
  font-family: 'Staatliches', cursive;
  padding: 2%;
}

.person-block {
  font-family: 'Nixie One', cursive;
  font-size: 1em;
  text-align: center;
  width: 25%;
  padding: 2%;
}

.people-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.people-image{
  height: 15vh;
  padding: 1%;
} 

.results {
  display: flex;
  flex-wrap: nowrap;
}

.section-title{
  font-size: 2em;
  line-height: 10em;
  font-family: 'Staatliches', cursive;
  text-align: center;
  align-content: center;
  background: url(/static/media/soldier.e97d3ed8.jpg);
  color: white;
}

.overview-title {
  background: url(/static/media/soldier.e97d3ed8.jpg);
}

.people-section-title {
  background: url(/static/media/soldier2.e5a6ada9.jpeg);
}

.results-title {
  background: url(/static/media/soldier3.2864ae4e.jpeg);
}

.news-title {
  background: url(/static/media/soldier4.10cf00bc.jpeg);
}

.contact-title {
  background: url(/static/media/soldier3.2864ae4e.jpeg);
}

.parallax { 
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.paper-odd, .paper-even{
  background: #1C2F27;
  color: white;
  font-family: 'Nixie One', cursive;
  font-size: 1.2em;
  line-height: 1.5em;
  padding: 3vh;
  line-height: 2em;
}

.paper-odd {
  background: white;
  color: #1C2F27;
}

.paper-title{
  font-family: 'Staatliches', cursive;
  font-size: 1em;
}

.news {
  display: flex;
  flex-wrap: nowrap;
}

.paper {
  display: flex;
  flex-wrap: nowrap;
}

.news-block, .paper-block {
  font-family: 'Nixie One', cursive;
  font-size: 1.5em;
  width: 25%;
  margin: 2%;
  padding: 2%;
  border: solid #1C2F27 .1em;
  transition: border-width 200ms ease-in-out;
  transition: font-weight 150ms ease-in-out;
  color: black;
  text-decoration: none;
}

.news-block:hover{
  border-width: .2em;
  text-decoration: underline;
}

.contact-info{
  font-size: 2em;
  text-align: center;
  padding: 5%;
  font-family: 'Nixie One', cursive;
  line-height: 3em;
}

.contact {
  display: flex;
  flex-wrap: nowrap;
  padding: 5%;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  font-family: 'Nixie One', cursive;
  font-size: 1.5em;
}

.small-icon{
  width: 5vw;
  transition: width 150ms ease-in-out;
}

.email-icon:hover{
  width: 6vw;
}

.footer{
  background: #1C2F27;
  color:white;
  font-family: 'Nixie One', cursive;
  text-align: center;
  padding: 1%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

@media only screen and (max-width: 800px) {
  .overview-text, .people-row, .paper, .results, .news{
    flex-wrap: wrap;
  }

  .overview-description {
    width: 100%;
  }

  .news-block{
    width: 100%;
  }

}
